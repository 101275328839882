.section_blog{
    position: relative;
    background: rgb(245,245,245);
    background: linear-gradient(180deg, rgba(245,245,245,1) 68%, rgba(255,255,255,1) 68%);
    padding-top: 9rem;
    padding-bottom: 8.4rem;
    @media (max-width: 1200px) {
        padding-top: 4rem;
        padding-bottom: 6.4rem;
    }
    .container{
        @media (max-width: 1200px) {
            padding-inline: 2rem;
        }
    }
    .blogSlider{
        margin-left: 2rem;
        margin-bottom: 2.2rem;

        @media (max-width: 1100px) {
            margin-top: 32px;
        }
        .swiper-slide{
            min-width: 300px;
            max-width: 300px;
        }
    }
    .blog{
        &--sub{
            @include fontTemplate(1.8rem, 4.6rem, $grey, $medium, $raleway, start);
            @media (max-width: 1200px) {
                font-size: 1.4rem;
                line-height: 2.4rem;
                text-align: center;
            }
        }
        &--title{
            @include fontTemplate(6.5rem, 1, $black500, $medium, $plus, start);
            @media (max-width: 1200px) {
                font-size: 4rem;
                line-height: 4.4rem;
                text-align: center;
            }
        }
        &--text{
            @include fontTemplate(1.6rem, 3.6rem, $grey, $medium, $raleway, end);
            @media (max-width: 1200px) {
                font-size: 1.4rem;
                line-height: 3.2rem;
                text-align: center;
            }
        }
        &--link{
            @include fontTemplate(1.6rem, 1, $black500, $bold, $rex, start);
            text-decoration: none;
            background: $yellow;
            min-height: 43px;
            padding-inline: 25px;
            display: flex;
            margin-top: 18px;
            width: fit-content;
            align-items: center;
            transition: $transition;
            text-transform: uppercase;
            @media (max-width: 1200px) {
                width: 100%;
                justify-content: center;
            }
            &:hover{
                color: $yellow;
                background: $black500;
            }
        }
        &--grid{
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 4.3rem;
            margin-top: 3.5rem;
            @media (max-width: 1200px) {
                display: none;
            }
        }
        &--card{
            display: flex;
            flex-direction: column;

            &:hover{
                .blog--imgHold{
                    img{
                        filter: grayscale(0);
                        transform: scale(1.1);
                    }
                }
            }
        }
        &--imgHold{
            height: 329px;
            width: 100%;
            overflow: hidden;
            @media (max-width: 1200px) {
                height: 228px;
            }
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: $transition;
                filter: grayscale(100%);
                &:hover{
                    transform: scale(1.1);
                }
            }
        }
        &--info{
            flex-grow: 1;
            padding-top: 3.6rem;
            padding-left: 5.3rem;
            padding-right: 3.2rem;
            padding-bottom: 2.2rem;
            background: #fff;
            border-bottom: 1px solid #D9D9D9;
            border-left: 1px solid #D9D9D9;
            border-right: 1px solid #D9D9D9;
            .blog--link{
                width: fit-content;
            }
        }
        &--tag{
            @include fontTemplate(1.6rem, 1, $grey, $medium, $raleway, start);
            margin-bottom: 2.9rem;
            @media (max-width: 1200px) {
                margin-bottom: 2rem;
                font-size: 1.6rem;
                line-height: 2rem;
            }
        }
        &--titleCard{
            @include fontTemplate(2.6rem, 3rem, $black500, $medium, $plus, start);
            margin-bottom: 2rem;
            letter-spacing: -0.02em;
            @media (max-width: 1200px) {
                font-size: 1.6rem;
                line-height: 2rem;
                margin-bottom: 1.3rem;
                min-height: auto;
            }
        }
        &--textCard{
            @include fontTemplate(1.6rem, 1.8rem, $grey, $medium, $raleway, start);
            margin-bottom: 18px;
            @media (max-width: 1200px) {
                font-size: 1.2rem;
                line-height: 1.4rem;
                margin-bottom: 2.2rem;
            }
        }

    }
}
