// DEFAULT
@import '../general/reset';
@import '../general/media-screen';
@import '../general/variable';
@import '../general/color';
@import '../general/fonts';
@import '../general/mixins';
@import '../general/common';
//libs
@import '../libs/animations';

//component
@import '../components/infinity_text';
@import '../components/section_blog';

#home{
    position: relative;
    overflow-x: hidden;
    .fixed-logo{
        top: 43vh;
        @media (max-width: 1200px) {
            width: 98px;
            height: 100px;
            top: 24.4rem;
            right: 2rem;
            svg{
                width: 100%;
                height: 100%;
            }
        }
    }
    .section_banner {
        // opacity: 0.3;
        position: relative;
        overflow: hidden;
        .nav-arrows{
            position: absolute;
            bottom: 112px;
            z-index: 150;
            right: 65px;
            display: flex;
            gap: 10px;
            @media (max-width: 1200px) {
                right: 20px;
                bottom: 90px;
                svg{
                    width: 20px;
                    height: 20px;
                }
            }
        }
        .scroll-down{
            position: absolute;
            bottom: 5%;
            left: 50%;
            transform: translate(-50%, 0%);
            z-index: 999;
            cursor: pointer;
            @media (max-width: 1200px) {
                width: 32px;
                height: 32px;
                bottom: 32px;
            }
        }
        .box_slider {
            height: 100vh;
            max-height: 988px;
            position: relative;
            max-width: 100%;
            overflow: hidden;

            // Slider Config
            // .swiper-wrapper {}

            .swiper-slide {
                width: 100%;
                height: 100%;
                object-fit: cover;
                display: flex;
                justify-content: center;
                align-items: flex-end;
                position: relative;
                z-index: 3;
                background: $black500;

            }

            .swiper-pagination {
                z-index: 150;
                padding: 30px 30px 30px 0px;
                bottom: 112px;
                max-width: 1100px;
                height: fit-content;
                display: flex;
                align-items: center;
                justify-content: left;
                gap: 2px;
                cursor: pointer;
                transition: all 0.3s ease;
                right: 65px;
                left: inherit;
                width: fit-content;
                .swiper-pagination-bullet {
                    width: 15px;
                    height: 2px;
                    background-color: #fff;
                    border-radius: 50%;
                    opacity: 1;
                    &-active {
                        background-color: $yellow;
                        opacity: 1;
                    }
                }
            }
            @keyframes sliderAnimation {
                0% {
                    background-size: 100%;
                }
                100% {
                    background-size: 110%;
                }
            }
            // Text Styles
            &-content {
                padding-bottom: 100px;
                display: flex;
                flex-direction: column;
                width: 100%;
                height: 100%;
                position: relative;
                z-index: 9;
                background-size: 100%;
                padding-inline: 100px;
                justify-content: flex-end;
                animation: sliderAnimation 30s ease infinite alternate;
                @media (max-width: 1200px) {
                    background-size: cover !important;
                }
                &::before{
                    content: "";
                    background: linear-gradient(180deg, rgb(0, 0, 0) 0%, rgb(255 255 255 / 0%) 50%, rgb(0, 0, 0) 100%);
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 9;
                    pointer-events: none;
                }
                @media (max-width: 1920px) {
                    .container {
                        padding: 0 5vw;
                    }
                }

                @media (max-width: 1200px) {
                    .container {
                        padding: 0 2.5vw;
                    }
                }

                @media (max-width: 768px) {
                    .container {
                        padding: 0 20px;
                    }
                }
            }

            &-text {
                @include fontTemplate(2.8rem,
                    1,
                    $white,
                    $bold,
                    $plus );
            }

            &-title {
                @include fontTemplate(4rem, 120%, $white, $medium, $plus);
                padding-top: 54px;
                max-width: 749px;
                max-height: 32rem;
                overflow: hidden;
                z-index: 9;
                position: relative;
                strong{
                    @include fontTemplate(4rem, 120%, $yellow, $medium, $plus);
                }
            }

            &-link {
                margin-top: 21px;
                width: fit-content;
                display: flex;
                gap: 6px;
                align-items: center;
                @include fontTemplate(1.6rem, 1, #F0F1F7, $regular, $raleway);
                text-decoration: none;
                position: relative;
                transition: $transition;
                z-index: 9;
                &::after{
                    content: "";
                    position: absolute;
                    left: 0;
                    width: 0%;
                    height: 2px;
                    background: $yellow;
                    bottom: -4px;
                    transition: $transition;
                }
                &:hover{
                    &::after{
                        width: 100%;
                    }
                }

            }

            &-image {
                position: absolute;
                inset: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                z-index: 1;
            }

            video {
                position: absolute;
                inset: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                z-index: 1;
            }
        }

        // ----------- Details ------------ //
        // Scroll


        // Triangle
        &--triangle-pink {
            position: absolute;
            left: 0;
            transform: translate(-15%, 0%);
            top: 13vh;
            width: 15vw;
            max-width: 300px;
            z-index: 1;
        }

        &--triangle-blue {
            position: absolute;
            left: 10vw;
            transform: translate(-15%, 0%);
            top: 0;
            width: 5vw;
            max-width: 100px;
            z-index: 1;
        }

        @media (max-width: 1599px) {
            .box_slider {
                .swiper-pagination {
                    max-width: 950px;
                }

                // Text Styles
                &-content {
                    padding-top: 154px;
                    padding-bottom: 100px;
                }

                &-title {
                    padding-top: 34px;
                }

                &-description {
                    padding-top: 34px;
                    padding-bottom: 25px;
                    width: 375px;
                    font-size: 2rem;
                }

                &-link {
                    width: 247px;
                    height: 54px;
                }
            }
        }

        @media (max-width: 1200px) {
            .box_slider {
                height: fit-content;
                max-height: fit-content;

                // Slider Config
                .swiper-slide {
                    align-items: start;

                    &::after {
                        height: 100%;
                        width: 100%;
                        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.08) 64%, #000 18%);
                    }


                }

                .swiper-pagination {
                    width: fit-content;
                    gap: 2px;
                    align-items: center;
                    justify-content: center;
                    padding: 0px 0px;
                    bottom: 115px;
                    right: 2rem;
                    .swiper-pagination-bullet {
                        width: 30px;
                        height: 5px;
                        border-radius: 0;
                    }
                }

                // Text Styles
                &-content {
                    padding-top: 0;
                    padding-bottom: 90px;
                    padding-inline: 2rem;
                    width: 100%;
                    min-height: Min(559px, 100dvh);
                }

                &-text {
                    font-size: 2rem;
                }

                &-title {
                    width: 90%;
                    font-size: 2rem;
                    line-height: 2.4rem;
                    strong{
                        font-size: 2rem;
                        line-height: 2.4rem;
                    }
                }

                &-description {
                    padding-top: 25px;
                    padding-bottom: 27px;
                    width: 90%;
                    font-size: 1.5rem;
                    line-height: 2rem;
                    opacity: 1;
                    color: #F0F1F7;
                }

                &-link {
                    width: 100%;
                    height: 69px;
                    font-size: 2rem;

                }
            }


        }
    }

    .section_video{
        padding-top: 108px;
        background: #fff;
        padding-bottom: 15rem;
        @media (max-width: 1200px) {
            padding-top: 6rem;
            padding-bottom: 6rem;
        }
        .container{
            @media (max-width: 1200px) {
                padding-inline: 2rem;
            }
        }
        &--sub{
            margin-top: 91px;
            @include fontTemplate(1.8rem, 4.6rem, $grey, $medium, $raleway, start);
            @media (max-width: 1200px) {
                font-size: 1.4rem;
                line-height: 2.4rem;
                margin-top: 0;
            }
        }
        &--title{
            @include fontTemplate(6.5rem, 7rem, $black500, $medium, $plus, start);
            @media (max-width: 1200px) {
                font-size: 4rem;
                line-height: 4.5rem;
            }
        }
        &--text{
            @include fontTemplate(1.6rem, 3.6rem, $grey, $medium, $raleway, start);
            margin-top: 2.7rem;
            max-width: 475px;
            @media (max-width: 1200px) {
                font-size: 1.4rem;
                line-height: 3.2rem;
            }
        }
        &--link{
            @include fontTemplate(1.6rem, 1, $black500, $bold, $rex, start);
            text-decoration: none;
            background: $yellow;
            min-height: 43px;
            padding-inline: 25px;
            display: flex;
            margin-top: 27px;
            width: fit-content;
            align-items: center;
            text-transform: uppercase;
            transition: $transition;
            @media (max-width: 1200px) {
                text-align: center;
                width: 100%;
                justify-content: center;
            }
            &:hover{
                color: $yellow;
                background: $black500;
            }
        }
        .section {
            position: relative;
            margin: auto;
            height: 500px;
            display: flex;
            justify-content: flex-end;
            @media (max-width: 1200px) {
                margin-top: 32px;
                height: 391px;
            }
            &::after{
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 10px;
                height: 28rem;
                background: $yellow;
                left: -45px;
                @media (max-width: 1399px) {
                    left: -109px;
                }
                @media (max-width: 1200px) {
                    left: 0;
                    width: 6px;
                    height: 168px;
                }
          }
        }
          .image-container, .video-container {
            position: relative;
            width: 100%;
            margin-bottom: 20px; /* Space between the elements */
          }
          .image-container{
            max-width: 472px;
            min-height: 522px;
            max-height: 552px;
            object-fit: cover;
            right: auto;
            @media (max-width: 1200px) {
                min-height: 315px;
                max-height: 315px;
                max-width: 284px;
            }
          }
          .image-container img, .video-container img {
            width: 100%;
            display: block; /* Remove default image margin */
            height: 100%;
            object-fit: cover;
            transition: all .3s ease-in-out;
            filter: grayscale(1);

            &:hover {
                filter: grayscale(0);
            }
          } 

          .video-container {
            position: absolute;
            bottom: -168px;
            left: -55px;
            max-width: 472px;
            max-height: 321px;
            right: 108px;
            left: auto;
            &:hover{
                .play-button{
                    img{
                        transform: scale(1.1);
                        opacity: 1;
                    }
                }
            }
            @media (max-width: 1200px) {
                max-width: 284px;
                max-height: 193px;
                left: 0;
                right: inherit;
                bottom: 0;
            }
          }

          .video-container .play-button {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            img{
                transition: $transition;
                opacity: .5;
            }
            @media (max-width: 1200px) {
                width: 60px;
            }
          }
    }

    .section_products{
        padding-top: 7rem;
        padding-bottom: 10rem;
        background: #F5F5F5;
        margin-top: 9rem;
        @media (max-width: 1200px) {
            margin-top: 4rem;
            padding-top: 4rem;
            padding-bottom: 4rem;
        }
        .container{
            @media (max-width: 1200px) {
                padding-inline: 2rem;
            }
        }
        .swiper-slide{
            @media (max-width: 1200px) {
                max-width: 236px;
            }
        }
        &--sub{
            @include fontTemplate(1.8rem, 4.6rem, $grey, $medium, $raleway, start);
            @media (max-width: 1200px) {
                font-size: 1.4rem;
                line-height: 2.4rem;
                text-align: center;
            }
        }
        &--title{
            @include fontTemplate(6.5rem, 1, $black500, $medium, $plus, start);
            @media (max-width: 1200px) {
                font-size: 4rem;
                line-height: 4.4rem;
                text-align: center;
            }
        }
        &--text{
            @include fontTemplate(1.6rem, 3.6rem, $grey, $medium, $raleway, end);
            @media (max-width: 1200px) {
                font-size: 1.4rem;
                line-height: 3.2rem;
                text-align: center;
                margin-top: 2rem;
            }
        }
        &--link{
            @include fontTemplate(1.6rem, 1, $black500, $bold, $rex, start);
            text-decoration: none;
            background: $yellow;
            min-height: 43px;
            padding-inline: 25px;
            display: flex;
            margin-top: 17px;
            width: fit-content;
            align-items: center;
            transition: $transition;
            text-transform: uppercase;
            @media (max-width: 1200px) {
                width: calc(100% - 40px);
                margin: 0 auto;
                margin-top: 4rem;
                justify-content: center;
            }
            &:hover{
                color: $yellow;
                background: $black500;
            }
        }
    }

    .section_partners{
        padding-top: 11rem;
        padding-bottom: 6.4rem;
        @media (max-width: 1200px) {
            padding-top: 7rem;
            padding-bottom: 5.4rem;
        }
        .container-fluid{
            @media (max-width: 1200px) {
                padding-inline: 2rem;
            }
        }

        .slider_partner{
            width: 100%;
            @media (max-width: 1200px) {
                margin-top: 3rem;
            }
            .swiper-slide{
                max-width: 220px;
                @media (max-width: 1200px) {
                    max-width: 140px;
                }
                &:hover {
                    img {
                        filter: none;
                    }
                }
                img{
                    height: 170px;
                    width: 100%;
                    filter: grayscale(1);
                    transition: $transition;
                    object-fit: contain;
                    @media (max-width: 1200px) {
                        width: 100%;
                        height: 40px;
                    }
                }
            }
        }
        .partners{
            &--content{
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-left: 8.4rem;
                gap: 8rem;
                @media (max-width: 1200px) {
                    padding-left: 0;

                }
            }
            &--title{
                @include fontTemplate(5rem, 5.5rem, $black500, $medium, $plus, start);
                border-left: 7px solid $yellow;
                max-width: 440px;
                padding-left: 1rem;
                @media (max-width: 1200px) {
                    font-size: 3.5rem;
                    line-height: 4rem;
                    padding-bottom: 3rem;
                    padding-left: 1.5rem;
                    padding-top: 1rem;
                }
            }
            &--box{
                display: grid;
                grid-template-columns: repeat(5, 1fr);
                gap: 6rem;
                @media (max-width: 1200px) {
                    display: none;
                }
                img{
                    width: 100%;
                    height: 58px;
                    aspect-ratio: 3.4;
                    object-fit: contain;
                }
            }
        }
    }

    .section_talk{
        max-height: 769px;
        min-height: 769px;
        padding-top: 17.6rem;
        position: relative;
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        @media (max-width: 1200px) {
            padding-top: 10rem;
            max-height: 696px;
            min-height: 696px;
        }
        &::after{
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: linear-gradient(180deg, rgb(0, 0, 0) 0%, rgb(255 255 255 / 0%) 50%, rgb(0, 0, 0) 100%);
        }
        .container{
            @media (max-width: 1200px) {
                padding-inline: 2rem;
            }
        }
        .talk{
            &--sub{
                @include fontTemplate(1.8rem, 4.6rem, $white, $medium, $raleway, start);
                @media (max-width: 1200px) {
                    font-size: 1.4rem;
                    line-height: 2.4rem;
                }
            }
            &--title{
                @include fontTemplate(6rem, 1, $white, $medium, $plus, start);
                @media (max-width: 1200px) {
                    font-size: 4rem;
                    line-height: 4.7rem;
                    margin-top: 2.5rem;
                }
            }
            &--text{
                @include fontTemplate(1.6rem, 2.4rem, $white, $medium, $raleway, start);
                @media (max-width: 1200px) {
                    font-size: 1.4rem;
                    line-height: 2.4rem;
                    margin-top: 6rem;
                }
            }
            &--link{
                @include fontTemplate(1.6rem, 1, $black500, $bold, $rex, start);
                text-decoration: none;
                background: $yellow;
                min-height: 43px;
                padding-inline: 25px;
                display: flex;
                margin-top: 25px;
                width: fit-content;
                align-items: center;
                text-transform: uppercase;
                transition: $transition;
                @media (max-width: 1200px) {
                    text-align: center;
                    width: 100%;
                    justify-content: center;
                }
                &:hover{
                    color: $yellow;
                    background: $black500;
                }
            }
            &--bg{
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: cover;
                z-index: -1;
                top: 0;
                right: 0;
            }
        }
    }

    .section_fast{
        position: relative;
        .container{
            @media (max-width: 1200px) {
                padding-inline: 0;
            }
        }
        &::after{
            content: '';
            position: absolute;
            width: 50%;
            height: 30vh;
            top: -128px;
            right: 0;
            background: #fff;
            z-index: 1;
            @media (max-width: 1200px) {
                display: none;
            }
        }
        .fast{
            &--grid{
                display: grid;
                grid-template-columns: 560px 1fr;
                gap: 9.4rem;
                background: #fff;
                position: relative;
                top: -128px;
                padding-top: 9.5rem;
                z-index: 99;
                padding-left: 12rem;
                @media (max-width: 1200px) {
                    display: flex;
                    flex-direction: column-reverse;
                    top: -67px;
                    padding-left: 0;
                    padding-top: 5.6rem;
                    width: calc(100% - 20px);
                    right: 0;
                    float: right;
                    padding-right: 2rem;
                }
            }
            &--imgs{
                height: 662px;
                position: relative;
                @media (max-width: 1200px) {
                    height: 417px;
                }
                &::after{
                    content: '';
                    position: absolute;
                    background: $yellow;
                    width: 10px;
                    height: 280px;
                    top: 0;
                    right: 0;
                    @media (max-width: 1200px) {
                        height: 177px;
                        width: 6.34px;
                    }
                }
            }
            &--img1{
                height: 522px;
                width: 472px;
                object-fit: cover;
                @media (max-width: 1200px) {
                    width: 299px;
                    height: 330px;
                }
            }
            &--img2{
                position: absolute;
                bottom: 90px !important;
                right: 65px !important;
                height: 160px;
                width: 160px;
                object-fit: cover;
            }
            &--acess{
                display: flex;
                flex-direction: column;
                align-items: flex-end;
            }
            &--title{
                @include fontTemplate(6.5rem, 1, $black500, $medium, $plus, end);
                max-width: 230px;
                @media (max-width: 1200px) {
                    font-size: 4rem;
                    line-height: 4.4rem;
                }
            }
            &--text{
                @include fontTemplate(1.6rem, 2.4rem, #363333, $medium, $raleway, end);
                margin-top: 26px;
                @media (max-width: 1200px) {
                    font-size: 1.4rem;
                    line-height: 2.1rem;
                    margin-top: 3.2rem;
                }
            }
            &--links{
                margin-top: 52px;
                display: flex;
                flex-direction: column;
                width: 100%;
                @media (max-width: 1200px) {
                    margin-top: 4rem;
                }
            }
            &--link{
                height: 97px;
                border-bottom: 1px solid #272525;
                transition: $transition;
                background: #fff;
                @include fontTemplate(3.6rem, 1, $black500, $medium, $plus, start);
                text-decoration: none;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-left: 2rem;
                padding-right: 1.6rem;
                span{
                    transition: $transition;
                }
                &:hover{
                    background: #F7F7F7;
                    span{
                        transform: rotate(-45deg);
                    }
                }
            }
        }
    }
}


// swiper products style
.swiperProduct{
    margin-top: 7rem;
    height: fit-content;
    @media (max-width: 1200px) {
        margin-top: 6rem;
    }
    .product-item{
        position: relative;
        &:hover{
            .product-item--image{
                filter: grayscale(0);
                img{
                    transform: scale(1.1);
                }
            }
        }
        &--image{
            width: auto;
            height: 372px;
            aspect-ratio: 1;
            overflow: hidden;
            filter: grayscale(100%);
            transition: $transition;
            @media (max-width: 1200px) {
                width: 100%;
                height: 237px;
            }
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: $transition;
            }
            &:hover{
                img{
                    transform: scale(1.1);
                }
            }
        }
        &--sub{
            @include fontTemplate(1.4rem, 2.5rem, $grey, $medium, $raleway, start);
            margin-top: 1.7rem;
            @media (max-width: 1200px) {
                margin-top: 1rem;
                font-size: 1.2rem;
                line-height: 1.58rem;
            }
        }
        &--title{
            @include fontTemplate(2.5rem, 3.5rem, #282B37, $bold, $rex, start);
            @media (max-width: 1200px) {
                font-size: 1.6rem;
                line-height: 2.2rem;
            }
        }
        &--text{
            @include fontTemplate(1.6rem, 2.5rem, #5A5D67, $regular, $raleway, start);
            margin-top: 11px;
            max-width: 242px;
            @media (max-width: 1200px) {
                font-size: 1.2rem;
                line-height: 1.58rem;
                margin-top: 0.7rem;
            }
        }

    }
}
